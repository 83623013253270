<table>
    <thead>
        <th>Id</th>
        <th>Name</th>
        <th>Description</th>
        <th>Longitude</th>
        <th>Latitude</th>
        <th>Altitude</th>
    </thead>
    <tbody>
        <tr *ngFor="let location of locations">
            <td>{{ location.id }}</td>
            <td>{{ location.name }}</td>
            <td>{{ location.description }}</td>
            <td>{{ location.longitude }}</td>
            <td>{{ location.latitude }}</td>
            <td>{{ location.alitude }}</td>
        </tr>
    </tbody>
</table>