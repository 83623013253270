import { Component, OnInit } from '@angular/core';
import { Location } from '@models';
import { LocationsService } from '@openapi';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  public locations:Location[] = [];

  constructor(private readonly locationService:LocationsService) { }

  ngOnInit(): void {
    this.locationService.getLocations().subscribe(data => {
      this.locations = data;
    });
  }

}
