<div class="battery-container">
    <div [ngClass]="charging()"></div>
    <div class="battery-level">
        <div *ngIf="BatteryPercentage > 75" class="segment" [ngClass]="getColor(BatteryPercentage)"></div>
        <div *ngIf="BatteryPercentage > 50" class="segment" [ngClass]="getColor(BatteryPercentage)"></div>
        <div *ngIf="BatteryPercentage > 25" class="segment" [ngClass]="getColor(BatteryPercentage)"></div>
        <div *ngIf="BatteryPercentage > 0" class="segment" [ngClass]="getColor(BatteryPercentage)"></div>
    </div>
    <div>
        {{BatteryPercentage}}%
    </div>
    <div>
        {{Voltage | number : '1.2-2'}}V
    </div>
    <div class="device" [ngClass]="isOnline ? 'green':'red'">{{ device }}</div>
</div>