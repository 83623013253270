/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum StatSpan {
    ALL = 'All',
    _90DAYS = '90Days',
    _30DAYS = '30Days',
    _24HRS = '24Hrs',
}
