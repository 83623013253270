<table>
    <thead>
        <tr>
            <th>Order</th>
            <th>Name</th>
            <th>Description</th>
            <th>Longitude</th>
            <th>Latitude</th>
            <th>Altitude</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let location of locations" data-id="{{ location.id}}">
            <td>{{ location.orderNo }}</td>
            <td>{{ location.name }}</td>
            <td>{{ location.description }}</td>
            <td>{{ location.longitude }}</td>
            <td>{{ location.latitude }}</td>
            <td>{{ location.alitude }}</td>
            <td><input type="checkbox" [checked]="location.isActive" ></td>
        </tr>
    </tbody>
</table>